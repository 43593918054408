var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("lbl_reason_of_rejection"),
        width: "50%",
        visible: _vm.visible
      },
      on: { cancel: _vm.handleCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { loading: _vm.loading },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "danger", loading: _vm.loading },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_reject")) + " ")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: { model: _vm.formData, rules: _vm.formRules }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_reason"), prop: "reason" } },
            [
              _c("a-textarea", {
                attrs: { placeholder: _vm.$t("lbl_reason") },
                model: {
                  value: _vm.formData.reason,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "reason", $$v)
                  },
                  expression: "formData.reason"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }