
































import { TruckingSettlementRateTableColumnDto } from "@/models/interface/trucking-settlement";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class SettingMasterRateModal extends Vue {
  @Prop({ type: Boolean, required: true, default: false })
  visible!: boolean;

  @Prop({ type: Array, required: false, default: [] })
  rateNames!: TruckingSettlementRateTableColumnDto[];

  columns = [
    {
      title: this.$t("lbl_missing_rate_accounts"),
      dataIndex: "rate",
    },
  ];

  handleCancel(): void {
    this.$emit("close");
  }
}
