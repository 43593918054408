var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("lbl_setup_master_rate"),
        width: "50%",
        visible: _vm.visible
      },
      on: { cancel: _vm.handleCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleCancel } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")
                  ]),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "trucking.master-rate" },
                        target: "_blank"
                      }
                    },
                    [
                      _c("a-button", { attrs: { type: "primary" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_set_master_rate_account")) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("a-table", {
        attrs: {
          rowKey: function(_record, index) {
            return index
          },
          columns: _vm.columns,
          "data-source": _vm.rateNames,
          pagination: false,
          size: "small"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }