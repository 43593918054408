var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("lbl_confirmation"),
        width: "50%",
        visible: _vm.visible
      },
      on: { cancel: _vm.handleCancel },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleCancel } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: { click: _vm.handlePosting }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_yes")) + " ")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("p", [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("lbl_confirmation_post_x_settlements_to_one_journal", {
                x: _vm.truckingSettlementIds.length
              })
            ) +
            " "
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }