



























import { useTruckingSettlement } from "@/hooks";
import MNotification from "@/mixins/MNotification.vue";
import { TruckingSettlementPostRequestDto } from "@/models/interface/trucking-settlement";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class PostingModal extends MNotification {
  @Prop({ type: Boolean, required: true, default: false })
  visible!: boolean;

  @Prop({ type: Array, required: true, default: [] })
  truckingSettlementIds!: Array<string>;

  loading = false;

  handleCancel(): void {
    this.$emit("close");
  }

  handlePosting(): void {
    const { postSettlement } = useTruckingSettlement();

    this.loading = true;

    const dto: TruckingSettlementPostRequestDto = {
      secureIds: this.truckingSettlementIds,
    };
    postSettlement(dto)
      .then(() => {
        this.showNotifSuccess("notif_settlement_posted_success");
        this.$emit("close");
        this.$emit("posted");
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
